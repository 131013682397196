export class MerchandisingModel {

    constructor (id, name, location, merchType, openingHour, closingHour) {
        this.id = id;
        this.name = name;
        this.location = location;
        this.merchType = merchType;
        this.openingHour = openingHour;
        this.closingHour = closingHour;
    };

};