export class CampingDto {

    constructor (id, name, longitude, latitude, type, capacity) {
        this._id = id;
        this._name = name;
        this._longitude = longitude;
        this._latitude = latitude;
        this._type = type;
        this._capacity = capacity;
    };

};