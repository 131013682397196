export class PerformanceModel {

    constructor(id, band, bandId, stage, timeFrame){
        this.id = id;
        this.band = band;
        this.bandId = bandId
        this.stage = stage;
        this.timeFrame = timeFrame;
    };

}