export class ToiletDto {

    constructor (id, name, longitude, latitude, type) {
        this._id = id;
        this._name = name;
        this._longitude = longitude;
        this._latitude = latitude;
        this._type = type;
    };

};