export class NewsModel {

    constructor(id, title, text, image, createdAt) {
        this.id = id;
        this.title = title;
        this.text = text;
        this.image = image;
        this.createdAt = createdAt
    };

};